import { useEffect, useContext, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import GlobalContext from "GlobalContext";


export default function Applications({ title , appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {
  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);

  const { app } = useContext(GlobalContext);
  const [err, setError] = useState(null);
  const [FilterHeightApplications, setFilterHeight] = useState(417);

  useEffect(() => {
    app.model.waitForOpen.promise.then(() => {
      setTimeout(async () => {
        app.model.enigmaModel.evaluate("$(=count({1}distinct [_col_id_submissions]))").then(async (reply) => {
          const SubmissionsNumberOfFilterRows = reply*27+40;
          console.log('SubmissionsNumberOfFilterRows/Number of rows: ', SubmissionsNumberOfFilterRows);
          setFilterHeight(SubmissionsNumberOfFilterRows);
        }).catch(err => {
          if(!err) setError(err);
        });
      }, 500);
    });
  }, [app, err])

  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="VXWetj"
              height="30px"
              showLoader={false}
            />
          </div> */}
        </div>
      </div>
          
      {/* <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div> */}

      <div className="container-fluid">

        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

              <div className="row justify-content-left">
                {/* KPIs Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of applications received */}
                      <QlikCard
                        id="0fdef2e5-8101-42ec-a692-94cbe07547f6"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of applications selected */}
                      <QlikCard
                        id="5e7d7ce9-04f0-4709-a4a9-74c71ef1bb83"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of applicants */}
                      <QlikCard
                        id="30888493-462b-427d-a1ec-1133165c9e4f"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of projects */}
                      <QlikCard
                        id="47e859b1-8083-4965-ba8d-c3d528d1754b"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

              </div>


              <div className="row justify-content-left">
                {/* Charts Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* pie chart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* pie chart */}
                      <QlikCard
                        id="KNnM"
                        // height="383px"
                        height="max(calc(100vh - 365px)/2, 100px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* map column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* map */}
                      <QlikCard
                        id="gYmCB"
                        // height="383px"
                        height="max(calc(100vh - 365px)/2, 100px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={false}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
               

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* bar chart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* bar chart */}
                      <QlikCard
                        id="pYzEt"
                        // height="383px"
                        height="max(calc(100vh - 365px)/2, 100px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
               

                </div>

              </div>

              <div className="row justify-content-left">
                {/* Table Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  {/* straight table */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* table */}
                      <QlikCard
                        id="YMmFu"
                        // height="383px"
                        height="max(calc(100vh - 365px)/2, 150px)"
                        showHeader={true}
                        showMaximize={true}
                        showExport={true}
                        onBeforeExportHandler={async ({  }) => {
                          //console.log(data);
                            await app.variable.setStringValue('export_link', '1');
                        }}
                        onAfterExportHandler={async ({  }) => {
                            app.variable.setStringValue('export_link', '0');
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

              </div>

            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  {/* Columns Selector */}
                  <QlikCard
                    id="ffjnjj"
                    // height="485px"
                    //height="max(calc(100vh - 557px), 100px)"
                    height={`max(min(${FilterHeightApplications}px - 25px, calc(100vh - 497px - 20px)), 184px)`} //557
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />
                  <div className="row justify-content-start">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 no-padding-buttons padding-left-buttons">
                      <QlikObject
                        id="XWvFBp"
                        // height="485px"
                        height="min(calc(3.5vh + 2px), 32px)"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 no-padding-buttons">
                      <QlikObject
                        id="pEpwftE"
                        // height="485px"
                        height="min(calc(3.5vh + 2px), 32px)"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 no-padding-buttons padding-right-buttons">
                      <QlikObject
                        id="PbzpGjw"
                        // height="485px"
                        //height="32px"
                        height="min(calc(3.5vh + 2px), 32px)"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                  </div>
                  {/* FilterPane */}
                  <QlikCard
                    id="f43d6331-2cc1-4661-a5ce-ef4c3c0c0ef5"
                    // height="485px"
                    height={`min(calc(252px - min(calc(3.5vh + 2px), 32px)), calc(100vh - max(min(${FilterHeightApplications}px - 25px, calc(100vh - 497px - 20px)), 184px) + 10px - 267px) - min(calc(3.5vh + 2px), 32px))`}
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />

            </div>

          </div>

          {/* <div className="row mb-2">
            <div className="col-md-2 col-sm-3 col-2">
              <QlikObject
                id="b4231dd2-2ce3-4d63-b394-838d3c6f0b0e"
                height="0px"
                showLoader={false}
              />
            </div>
          </div> */}
          
        </div>
    </section>
  );
}
